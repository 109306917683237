import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Main = (props) => {
  const allowedGroups = ["Asosiy"];
  return (
    <>
      {allowedGroups.every((allow) => props.permissions.includes(allow)) && (
        <li className="my-item">
          <Link to="/home">
            <i className="fa-solid fa-table-columns"></i>
            <span className="my-link_name">Asosiy</span>
          </Link>
        </li>
      )}
    </>
  );
};

const Analitika = (props) => {
  const allowedGroups = ["Analitika"];
  const [show, setShow] = useState(false);
  return (
    <>
      {allowedGroups.every((allow) => props.permissions.includes(allow)) && (
        <li className={show ? "my-item showMenu" : "my-item"}>
          <div className="my-icon-link">
            <Link to="/">
              <i className="fa-solid fa-chart-bar"></i>
              <span className="my-link_name">Analitika</span>
            </Link>
            <i
              className="fa-solid fa-chevron-down my-arrow"
              onClick={() => setShow(!show)}
            ></i>
          </div>
          <ul className="my-sub-menu">
            <li>
              <Link className="my-link_name" to="/">
                Analitika
              </Link>
            </li>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/">Mahsulotlar</Link>
            </li>
            <li>
              <Link to="/">Sotuv</Link>
            </li>
            <li>
              <Link to="/filters">Filtr</Link>
            </li>
            <li>
              <Link to="/survey">So'rovnomalar</Link>
            </li>
          </ul>
        </li>
      )}
    </>
  );
};

const Admin = (props) => {
  const allowedGroups = ["Admin"];
  const [show, setShow] = useState(false);
  return (
    <>
      {allowedGroups.every((allow) => props.permissions.includes(allow)) && (
        <li className={show ? "my-item showMenu" : "my-item"}>
          <div className="my-icon-link">
            <Link to="/">
              <i className="fa-regular fa-user"></i>
              <span className="my-link_name">Admin</span>
            </Link>
            <i
              className="fa-solid fa-chevron-down my-arrow"
              onClick={() => setShow(!show)}
            ></i>
          </div>
          <ul className="my-sub-menu">
            <li>
              <Link className="my-link_name" to="/">
                Admin
              </Link>
            </li>
            <li>
              <Link to="/roles">Rol</Link>
            </li>
            <li>
              <Link to="/admins">Xodim</Link>
            </li>
            <li>
              <Link to="/regions">Hudud</Link>
            </li>
          </ul>
        </li>
      )}
    </>
  );
};

const Katalog = (props) => {
  const allowedGroups = ["Katalog"];
  const [show, setShow] = useState(false);

  return (
    <>
      {allowedGroups.every((allow) => props.permissions.includes(allow)) && (
        <li className={show ? "my-item showMenu" : "my-item"}>
          <div className="my-icon-link">
            <Link to="/">
              <i className="fa-solid fa-list"></i>
              <span className="my-link_name">Katalog</span>
            </Link>
            <i
              className="fa-solid fa-chevron-down my-arrow"
              onClick={() => setShow(!show)}
            ></i>
          </div>
          <ul className="my-sub-menu">
            <li>
              <Link className="my-link_name" to="/">
                Katalog
              </Link>
            </li>
            {props.permission && (
              <li>
                <Link to="/brands">Brend</Link>
              </li>
            )}
            {props.permission && (
              <li>
                <Link to="/categories">Kategoriya</Link>
              </li>
            )}
            {props.permission && (
              <li>
                <Link to="/collections">Kolleksiya</Link>
              </li>
            )}
            {props.permission && (
              <li>
                <Link to="/designs">Dizayn</Link>
              </li>
            )}
            {props.permission && (
              <li>
                <Link to="/formats">Format</Link>
              </li>
            )}
            {props.permission && (
              <li>
                <Link to="/sizes">O'lcham</Link>
              </li>
            )}


            <li>
              {props.permission && <Link to="/parameters">Xususiyatlari</Link>}
            </li>
          </ul>
        </li>
      )}
    </>
  );
};

const Logistika = (props) => {
  const allowedGroups = ["Logistika"];
  const [show, setShow] = useState(false);
  return (
    <>
      {allowedGroups.every((allow) => props.permissions.includes(allow)) && (
        <li className={show ? "my-item showMenu" : "my-item"}>
          <div className="my-icon-link">
            <Link to="/">
              <i className="fa-solid fa-truck"></i>
              <span className="my-link_name">Logistika</span>
            </Link>
            <i
              className="fa-solid fa-chevron-down my-arrow"
              onClick={() => setShow(!show)}
            ></i>
          </div>
          <ul className="my-sub-menu">
            <li>
              <Link className="my-link_name" to="/">
                Logistika
              </Link>
            </li>
           
            {/* <li>
              <Link to="/transfer">Transfer</Link>
            </li> */}
            <li>
              <Link to="/warehouse">Omborlar</Link>
            </li>
            <li>
              <Link to="/drivers">Kuryerlar</Link>
            </li>
            <li>
              <Link to="/delivery-methods">Yetkazish metodlari</Link>
            </li>
          </ul>
        </li>
      )}
    </>
  );
};

const Sotuv = (props) => {
  const allowedGroups = ["Sotuv"];
  const [show, setShow] = useState(false);
  return (
    <>
      {allowedGroups.every((allow) => props.permissions.includes(allow)) && (
        <li className={show ? "my-item showMenu" : "my-item"}>
          <div className="my-icon-link">
            <Link to="/">
              <i className="fa-solid fa-tag"></i>
              <span className="my-link_name">Sotuv</span>
            </Link>
            <i
              className="fa-solid fa-chevron-down my-arrow"
              onClick={() => setShow(!show)}
            ></i>
          </div>
          <ul className="my-sub-menu">
            <li>
              <Link className="my-link_name" to="/">
                Sotuv
              </Link>
            </li>
            <li>
              <Link to="/order">Buyurtmalar</Link>
            </li>
            <li>
              <Link to="/cancelled-order">Bekor qilingan buyurtmalar</Link>
            </li>
            <li>
              <Link to="/services">Xizmatlar</Link>
            </li>
            <li>
              <Link to="/showroom">Shourumlar</Link>
            </li>
            <li>
              <Link to="/">Assistentlar</Link>
            </li>
            <li>
              <Link to="/clients">Xaridorlar</Link>
            </li>
            <li>
              <Link to="/machine">Stanoklar</Link>
            </li>
          </ul>
        </li>
      )}
    </>
  );
};

const Info = (props) => {
  const allowedGroups = ["Kontent"];
  const [show, setShow] = useState(false);
  return (
    <>
      {allowedGroups.every((allow) => props.permissions.includes(allow)) && (
        <li className={show ? "my-item showMenu" : "my-item"}>
          <div className="my-icon-link">
            <Link to="/">
              <i className="fa-solid fa-circle-info"></i>
              <span className="my-link_name">Info</span>
            </Link>
            <i
              className="fa-solid fa-chevron-down my-arrow"
              onClick={() => setShow(!show)}
            ></i>
          </div>

          <ul className="my-sub-menu">
            <li>
              <Link className="my-link_name" to="">
                Info
              </Link>
            </li>
            <li>
              <Link to="/main">Asosiy</Link>
            </li>
            <li>
              <Link to="/banner">Banner</Link>
            </li>
            <li>
              <Link to="/opportunity">Imkoniyatlar</Link>
            </li>
            <li>
              <Link to="/post">Yangiliklar</Link>
            </li>
            <li>
              <Link to="/social">Ijtimoiy tarmoqlar</Link>
            </li>
            <li>
              <Link to="/contact">Aloqa</Link>
            </li>
            <li>
              <Link to="/pages">Sahifalar</Link>
            </li>
          </ul>
        </li>
      )}
    </>
  );
};

const Magazin = (props) => {
  const allowedGroups = ["Magazin"];
  const [show, setShow] = useState(false);
  return (
    <>
      {allowedGroups.every((allow) => props.permissions.includes(allow)) && (
        <li className={show ? "my-item showMenu" : "my-item"}>
          <div className="my-icon-link">
            <Link to="/">
              <i className="fa-solid fa-shop"></i>
              <span className="my-link_name">Magazin</span>
            </Link>
            <i
              className="fa-solid fa-chevron-down my-arrow"
              onClick={() => setShow(!show)}
            ></i>
          </div>

          <ul className="my-sub-menu">
            <li>
              <Link className="my-link_name" to="">
                Magazin
              </Link>
            </li>
            <li>
              <Link to="/units">Остаток</Link>
            </li>
            <li>
              <Link to="/specialorders">Maxsus buyurtmalar</Link>
            </li>
            <li>
              <Link to="/logorders">Logistika</Link>
            </li>
            <li>
              <Link to="/shop-machine">Stanoklar</Link>
            </li>
           
          </ul> 
        </li>
      )}
    </>
  );
};

export { Main, Analitika, Admin, Katalog, Logistika, Sotuv, Info, Magazin };
